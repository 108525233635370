import React from "react";
import { useEffect } from "react";

export default function JapanDirectProject(){
    useEffect(() => {
        document.title = "Margarita Shipping (Transport Website)"; // Set your default title here
      });
return(
<div>
    <div class="container single-project">
        <div class="row my-5 g-5">
            <div class="col-lg-8 my-4">
                <h2 class="mb-4">Margarita Shipping (Transport Website)</h2>
                <img src="../images/WhatsApp Image 2024-06-24 at 3.21.58 PM.jpeg" class="img-fluid project-img"
                    alt="project-img" />
                <p class="mt-5">
                Margarita Shipping is dedicated to providing world-class shipping and logistics solutions tailored to meet the unique needs of clients. We develop the front-end and fix bugs in the site. 
                </p>
                <div class="row my-5">
                    <div class="col-md-6 my-2 my-lg-0">
                        <img src="../images/Screenshot 2024-05-27 142514.png" class="img-fluid project-img"
                            alt="project-img" />
                    </div>
                    <div class="col-md-6 my-2 my-lg-0">
                        <img src="../images/Screenshot 2024-05-27 142629.png" class="img-fluid project-img"
                            alt="project-img" />
                    </div>
                </div>

            </div>
            <div class="col-lg-4 my-4 px-5">
                <div class="row flex-column">
                    <div class="col-12">
                        <h3>Category</h3>
                        <div class="badges my-4">
                            <span class="badge badge-pill badge-dark p-2 m-2">Web App Development</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">Website</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">Custom Web App</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">Customize Design</span>
                        </div>
                    </div>
                    <div class="col-12 mt-5 tech">
                        <h3>Technologies</h3>
                        <h5 class="my-3">Front-End</h5>
                        <div class="badges my-4">
                            <span class="badge badge-pill badge-dark p-2 m-2">HTML5</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">CSS3</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">JavaScript</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">JQuery</span>
                            <span class="badge badge-pill badge-dark p-2 m-2">Boostrap</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
)
}