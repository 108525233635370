import React from "react";
import { useEffect } from "react";
import WhyUs from "./Sub-Sections/Why-us";
import About from "./About";
import Services from "./Services";
import Contact from "./Contact";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

export default function Home() {
  useEffect(() => {
    document.title = "Psoft - Solutions For Your Business"; // Set your default title here
  });
  return (
    <div>
      <Helmet>
        <meta property="title" content="Psoft - Solutions For Your Business" />
        <meta name="description" content="Psoft: Your Trusted Software Development Partner. We specialize in delivering software solutions to streamline your business operations. Whether you need web-based applications or enterprise systems, you can confidently rely on Psoft to turn your vision into reality." />
        <meta name="keywords" content="keyword1, keyword2, keyword3" />
      </Helmet>
      <div class="hero_area">
        <section class="slider_section ">
          <div id="customCarousel1" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="container ">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="img-box">
                        <img src="images/contact-img.png" alt="" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="detail-box">
                        <h1>Build your brand and make your business profitable</h1>
                        <p>
                          Unlock the power that increase your sales processes,
                          enhances customer engagement, and drives revenue
                          growth. With Psoft, you'll get solutions that can also
                          accelerate your sales journey.
                        </p>
                        <div class="btn-box">
                          <Link to={"/services"} className="btn1">
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item ">
                <div class="container ">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="img-box">
                        <img src="images/slider-img.png" alt="" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="detail-box">
                        <h1>
                          Manage Your Business headache with customize softwares
                        </h1>
                        <p>
                          Feel easy and manage your business. We are here to
                          craft customized ERP solutions that can streamline
                          your operations, boost productivity, and drive growth.
                          Unlock your business's potential with us
                        </p>
                        <div class="btn-box">
                          <Link to={"/services"} className="btn1">
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item ">
                <div class="container ">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="img-box">
                        <img src="images/img-3.png" alt="" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="detail-box">
                        <h1>
                          Be a online store owner and sale your products now!
                        </h1>
                        <p>
                          Step into the world of online business ownership.
                          Start your own online store effortlessly and showcase
                          your products to a global audience. Embrace the future
                          of commerce today!
                        </p>
                        <div class="btn-box">
                          <Link to={"/services"} className="btn1">
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ol class="carousel-indicators">
              <li
                data-target="#customCarousel1"
                data-slide-to="0"
                class="active"
              ></li>
              <li data-target="#customCarousel1" data-slide-to="1"></li>
              <li data-target="#customCarousel1" data-slide-to="2"></li>
            </ol>
          </div>
        </section>
      </div>

      <Services />

      <About />
      <Contact />
    </div>
  );
}
