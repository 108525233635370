import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
const width = {
  width: "100%",
};

const adjust = {
  alignItmes: "flesx-end",
  marginBottom: "45px",
};
export default function Contact() {
  useEffect(() => {
    document.title = "Contact Us";
  });

  const [formDataCall, setFormDataCall] = useState({
    name: "",
    phone_number: "",
    email: "",
    message: "",
  });

  const handleInputChangeCall = (event) => {
    const { name, value } = event.target;
    setFormDataCall((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const Call = async (event) => {
    event.preventDefault();
    const btn = document.getElementById("btn");
    btn.disabled = true;
    btn.innerHTML = "SENDING...";
    const response = await axios.post(
      "https://laravelreact.psofts.online/api/add-call",
      formDataCall
    );
    btn.innerHTML = "SEND";
    btn.disabled = false;

    if (response.data.status === 200) {
      Swal.fire({
        icon: "success",
        title: response.data.message,
        text: "We will contact you via email or phone number.",
        focusConfirm: false,
      });
      const closeButton = document.querySelector('[data-dismiss="modal"]');
      if (closeButton) {
        closeButton.click();
      }
      setFormDataCall({
        name: "",
        phone_number: "",
        email: "",
        message: "",
      });
    }
    if (response.data.status === 500) {
      Swal.fire({
        icon: "warning",
        title: response.data.error,

        focusConfirm: false,
      });
    }
  };

  return (
    <div>
      <section class="contact_section layout_padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-5 col-lg-4 offset-md-1">
              <div class="form_container">
                <div class="heading_container">
                  <h2>Request A Call back</h2>
                </div>
                <form id="call-form" onSubmit={Call}>
                  <div>
                    <input
                      type="text"
                      placeholder="Full Name "
                      onChange={handleInputChangeCall}
                      name="name"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      placeholder="Email"
                      onChange={handleInputChangeCall}
                      name="email"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Phone number"
                      onChange={handleInputChangeCall}
                      name="phone_number"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      class="message-box"
                      placeholder="Message"
                      onChange={handleInputChangeCall}
                      name="message"
                      required
                    />
                  </div>
                  <div class="d-flex">
                    <button id="btn">SEND</button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6 offset-md-1 m-auto mb-md-5" style={adjust}>
              <div class="detail-box">
                <div class="img-box">
                  <img
                    src="../images/proccess-img.png"
                    alt="proccess image"
                    style={width}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
