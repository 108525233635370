import { useEffect } from 'react';
import React from "react";

export default function Web() {
  const height = {
    height: "338px",
  };
  useEffect(() => {

    document.title = 'Web Application'
  })

  return (
    <div>
      <section class="about_section service layout_padding">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="detail-box">
                <div class="heading_container">
                  <h2>Business Website</h2>
                </div>
                <p>
                  A fully functional website that help you to enchance your business
                  and also increase the chances of your sales,In today's digital
                  age, a robust online presence is the cornerstone of success for
                  any business. We are dedicated to crafting a fully
                  functional website that not only enhances your business but
                  significantly elevates your sales potential. <b> Why a Business
                    Website Matters</b>, In a world where customers turn to the internet
                  for information and services, your business website serves as your
                  digital storefront. It's more than just a virtual business card;
                  it's a dynamic platform that can drive growth, foster credibility,
                  and facilitate interactions with your audience.
                </p>
                <a href="" data-toggle="modal" data-target="#quote">Get Free consultaion</a>              </div>
            </div>
            <div class="col-md-6 ">
              <div class="img-box">
                <img src="../images/web-img.png" alt="website image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
