import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const height = {
  height: "338px",
};
export default function Service() {
  useEffect(() => {
    document.title = "Our Services";
  });
  return (
    <div>
      <section class="service_section layout_padding">
        <div class="container">
          <div class="heading_container">
            <h2>Our Services</h2>
            <p>
              Your Trusted Partner in Software Development. We develop software
              solutions to empower businesses.
            </p>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <div class="box" style={height}>
                <div class="img-box">
                  <img src="images/business website.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>Business Website</h5>
                  <p>
                    A fully functional website that help you to enchance your
                    business and also increase the chances of your sales,
                  </p>
                  <Link to={"/services/web-application"}>
                    <span>Read More</span>
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="box" style={height}>
                <div class="img-box">
                  <img src="images/s2.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>On page SEO</h5>
                  <p>
                    We also offer on page SEO optimization to enhance your
                    website ranking,
                  </p>
                  <Link to={"/services/seo"}>
                    <span>Read More</span>
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="box" style={height}>
                <div class="img-box">
                  <img src="images/s3.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>E-commerce Store</h5>
                  <p>
                    Being a store owner online and get a great online presence
                    to sell your product for an affordable price,
                  </p>
                  <Link to={"/services/e-commerce"}>
                    <span>Read More</span>
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="box" style={height}>
                <div class="img-box">
                  <img src="images/s4.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>ERP Development</h5>
                  <p>
                    Have a successfull company! and headache to manage it so now
                    develop a software to manage finance, inventory, HR.
                  </p>
                  <Link to={"/services/erp"}>
                    <span>Read More</span>
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-box">
            <a href="" data-toggle="modal" data-target="#quote">
              Get a free consultation now!
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
