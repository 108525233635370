import React, { useEffect } from "react"; // Combine imports
import { useLocation } from "react-router-dom"; // Import useLocation for routing

export default function About() {
  const location = useLocation(); // Use useLocation to get the current path

  useEffect(() => {
    document.title = 'About Us'; // Set the document title
  }, []); // Add an empty dependency array to run only on mount

  const myStyle = {
    backgroundColor: '#ffff', 
    color: 'black',           
  };

  return (
    <div>
      <section
        className="about_section layout_padding"
        style={location.pathname === "/about" ? myStyle : {}}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="detail-box">
                <div className="heading_container">
                  <h2>About Us</h2>
                </div>
                <p>
                  Welcome to Psoft – Your Destination for Tailored Software
                  Solutions. At Psoft, we have expertise in custom software
                  development, web and e-commerce solutions, and more. As your
                  trusted partner in the digital realm, we're here to fuel your
                  innovation. Explore our comprehensive range of services and
                  embark on a journey of technological excellence with Psoft by
                  your side.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box">
                <img src="images/about-img.png" alt="About Psoft" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
